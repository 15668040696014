.Footer {
    width: 100%;

    color: var(--fontColor);
    font-size: 14px;
    position: relative;
    bottom: 0;
    text-align: center;
    padding: 50px 0px;
}


.Contact {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 30px;
    padding: 200px 0px;
}

.Contact div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 10px 0px;
}

.Contact div p {
    color: var(--fontColor);
    font-weight: 600;
    font-size: 14px;
}

.Contact div a {
    text-decoration: none;
    color: var(--fontHover);
    font-size: 16px;
    font-weight: 400;
}
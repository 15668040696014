.About {
    width: 100%;
    max-width: 1200px;
    margin: 50px auto;
    display: flex;
    gap: 20px;   
}

.about__left {
    flex: 0.3;
    display: flex;
    justify-content: center;
}

.about__left img {
    height: 300px;
}

.about__right {
    flex: 0.7;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.about__right p {
    font-size: 14px;
    color: var(--fontColor);
}

@media screen and (max-width: 768px) {
    .About {
        flex-direction: column;
        gap: 50px;
        padding: 10px 0px;
    }
    .about__right {
        width: 100%;
        padding: 0px 20px;
        gap: 50px;
    }
    
    .about__right p {
        font-size: 14px;
    }

}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap');

* {
    transition: all 300ms;
}

:root {
    --fontFamily: 'Roboto Mono', monospace;
    --fontColor: #787878;
    --fontHover: #a8a8a8;
}

.Header {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: var(--fontFamily);
    margin: 50px auto;
}

header {
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-weight: 200;
    font-family: var(--fontFamily);
    color: var(--fontColor);
}

.toggle {
    display: none;
}

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-nav {
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    width: 700px;
}

.menu-nav li a {
    text-decoration: none;
    font-family: var(--fontFamily);
    color: var(--fontColor);
    font-size: 16px;
    font-weight: 400;
}

.menu-nav li a:hover {
    color: var(--fontHover);

}





/*==========================
Sidebar navigation
========================*/
@media screen and (max-width: 768px) {

    .Header {
        width: 100%;
        height: 50px;
        display: flex;

        font-family: var(--fontFamily);
        margin: 20px auto;
    }

    header {
        text-align: left;
        font-size: 40px;
        padding-left: 20px;
    }

    .navbar {
    position: relative;
    }

    .navbar button {
        cursor: pointer;
        background: transparent;
        border: none;
        font-size: 20px;
    }

    .toggle {
        display: block;
    }

    .navbar .toggle {
        position: absolute;
        right: 20px;
        top: -40px;
        cursor: pointer;
        background: transparent .0;
        border: none;
    }

    .menu-nav {
        flex-direction: column;

        position: relative;
        list-style: none;
        background: #fff;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        overflow: hidden;
        z-index: 999;
        font-size: 18px;
        box-shadow: 0 10px 15px -3px rgb(46 41 51 / 8%), 0 4px 6px -2px rgb(71 63 79 / 16%);
        transform: translateX(100%);
        transition: transform ease-in-out 0.2s;
        /* transition: width ease 0.2s; */
    }

    .menu-nav.show-menu {
        width: 100%;
        transform: translateX(0px);
        position: relative;
        top: 40px;
        border: 1px solid var(--fontHover);
    }

    .menu-nav li a, .menu-nav li span {
        display: block;
        padding: 2rem;
        font-size: 20px;
    }

}
.Sculpture {
    width: 100%;
    max-width: 1200px;
    margin: 50px auto;

    display: flex;
    flex-direction: column;
    gap: 20px;    
}

.card {
    object-fit: cover;
    padding: 20px;
}

.card:hover {
    opacity: 0.8;
    border: 1px solid var(--fontColor);
    cursor: pointer;
}


.card img {
    width: 100%;
}

.card p {
    height: 50px;
    padding: 10px 5px;
}

.sculpture__2 {
    display: flex;
    gap: 20px;
}

.sculpture__2__left {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sculpture__2__right {
    width: 50%;
}

.sculpture__2__right .card{
    width: 100%;
    height: 100%;
}

.sculpture__2__right .card img {
    height: calc(100% - 50px);
}


.sculpture__3 {
    width: 100%;
    display: flex;
    gap: 20px;
}

.sculpture__3 .card {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sculpture__3__left {
    width: 50%;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    gap: 20px;
}

.sculpture__3__left .card:nth-child(1) {
    grid-row-start: 1;
    grid-row-end: 2;
}

.sculpture__3__left div:nth-child(2) {
    grid-row-start: 2;
    grid-row-end: 4;
}

.sculpture__3__left div:nth-child(2) img {
    height: calc(100% - 50px);
    object-fit: 100%;
}


.sculpture__3__right {
    width: 50%;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    gap: 20px;
}

.sculpture__3__right div:nth-child(1) {
    grid-row-start: 1;
    grid-row-end: 3;
}

.sculpture__3__right div:nth-child(1) img {
    height: calc(100% - 50px);
    object-fit: 100%;
}


@media screen and (max-width: 768px) {
    .Sculpture {
        padding: 0px 10px;
    }

    .card {
        padding: 0px;
    }
}
.Landscape {
    width: 100%;
    max-width: 1200px;
    margin: 50px auto;

    display: flex;
    flex-direction: column;
    gap: 20px;   
}

.Landscape div:nth-child(2) {
    display: flex;
}

.Landscape div:nth-child(2) .card {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .Landscape {
        padding: 0px 10px;
    }

    .Landscape div:nth-child(2) {
        gap: 20px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap');


* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Roboto Mono', monospace;

}


.Home {
    width: 100%;
    max-width: 1200px;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home img {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    object-fit: contain;
    padding: 20px;
}